<template>
  <div id="map">
    <canvas id="extraction-canvas"></canvas>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { eventBus } from "@/main.js";
import axios_services from '@/axios/axios-services.js'
//import * as GeoTIFF from 'geotiff';
import "leaflet-spin";

export default {
  name: "Map",
  props: ["invalidateSize"],
  data() {
    return {
      map: null,
      baseMaps: {},
      sidebarOpened: true,
      mapConfiguration: {
        center: [44.54, 10.96],
        zoom: 8,
        maxZoom: 18,
      },
      videoLayer: null,
      videoElement: null,
      framesPerSecond: 4,
      currentFrame: null,
      lastFrame: null,
      jumpToNextFrame: false,
      videoReadyChecker: null,
      videoWatcher: null,
      gridLayers: {},
      selectedGridTile: null,
      tileMetadata: {},
      tileBoundsLoading: false,
      tileMetadataLoading: false,
      videoPlaying: false,
      videoPositionDragging: false,
      mapSpinner: false,

      timeseriesExtractionMode: false,
      timeseriesExtractionModalData: null,
      firstExtractionFrame: null,
      lastExtractionFrame: null,
      extractionCounter: 0,
    };
  },
  created: function() {
    eventBus.$on("video-toggle-playing", () => {
      if (this.videoElement != null) {
        if (this.videoElement.paused) {
          this.playVideo();
        }
        else {
          this.pauseVideo();
        }
      }
    });
    eventBus.$on("extract-single-frame", (data) => {
      this.extractCurrentFrameToGeotiff(data);
    });
    eventBus.$on("extract-timeseries", (data) => {
      console.log("extract timeseries: ", data);
      this.extractTimeSeries(data); 
    });
    eventBus.$on("cancel-timeseries", () => {
      this.timeseriesExtractionMode = false;
      this.firstExtractionFrame = null;
      this.lastExtractionFrame = null;
      this.$store.commit("setToggleLoadingViewProgress", false);
    });
    eventBus.$on("video-next-frame", () => {
      //console.log('go to next frame');
      //console.log('before next: current time: ' + this.videoElement.currentTime + ' and frame ' + this.currentFrame);
      this.jumpToNextFrame = true;
      this.lastFrame = this.currentFrame;
      this.videoElement.playbackRate = 1;
      this.videoElement.play();
    });
    eventBus.$on("video-first-frame", () => {
      //console.log('go to first frame');
      this.videoPlaying = false;
      this.jumpToTime(0);
      this.stopVideoWatcher();
    });
    eventBus.$on("time-slider-drag-start", () => {
      this.videoPositionDragging = true;
    });
    eventBus.$on("time-slider-change", (timeSliderValue) => {
      if (!this.videoPositionDragging) {
        this.jumpToTime(((timeSliderValue - 1) * 0.25) + 0.001);
      }
    });
    eventBus.$on("time-slider-drag-end", (timeSliderValue) => {
      this.videoPositionDragging = false;
      this.jumpToTime(((timeSliderValue - 1) * 0.25) + 0.001);
    });
    eventBus.$on("show-modal-single-download", () => {
      this.pauseVideo();
    });
    eventBus.$on("download-single-image-without-ref", (data) => {
      this.extractCurrentFrameToJPEG(data);
    });
    eventBus.$on("update-location", (location) => {
      if (location === 'africa') {
        this.map.flyTo(new L.LatLng(12.16, -14.91), 8);
        this.setZoom(8);
      } else if (location === 'europe') {
        this.map.flyTo(new L.LatLng(44.54, 10.96), 8);
      } else if (location === 'turkey') {
        this.map.flyTo(new L.LatLng(38.5, 35), 6.5);
      }
    });
  },
  mounted() {
    this.initMap();
  },
  methods: {
    /**
     * This function initializes the leaflte map
     */
    initMap: function () {
      //const ref = this;
      this.map = L.map("map").setView(
        this.mapConfiguration.center,
        this.mapConfiguration.zoom
      );
      L.control.scale({ imperial: false, maxWidth: 300, position: 'topright' }).addTo(this.map);
      this.map.spin(false);
      this.loadS2Tiles();

      this.baseMaps = {
        "EOX S2 Cloudless": L.tileLayer(
          "https://s2maps-tiles.eu/wmts?layer=s2cloudless-2020_3857&style=default&tilematrixset=GoogleMapsCompatible&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fjpeg&TileMatrix={z}&TileCol={x}&TileRow={y}",
          {
            maxZoom: this.mapConfiguration.maxZoom,
            attribution: '<a href="https://s2maps.eu" target="blank">Sentinel-2 cloudless</a> - by <a href="https://eox.at/" target="blank">EOX IT Services GmbH</a> (Contains modified Copernicus Sentinel data 2020)',
          }
        ),
        "Open Street Map": L.tileLayer(
          "https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png",
          {
            maxZoom: this.mapConfiguration.maxZoom,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          }
        ),
      };

      this.baseMaps['EOX S2 Cloudless'].addTo(this.map);
      L.control.layers(this.baseMaps, null).addTo(this.map); 

      this.map.createPane('videoPane').style.zIndex = 1000;
    },
    addS2Tiles(tileData) {
      for (var i=0; i<tileData.length; i++) {
        let id = tileData[i].tile_id;
        let geom = tileData[i].geom;
        //console.log('adding to map: ' + id);
        var tileOptions = { 
          tileID: id, 
          color: "#ff7800", 
          weight: 1, 
          dashArray: '3, 3',
          fill: true, 
          fillColor: "#ff7800",
          fillOpacity: 0, 
          interactive: true
        };
        this.gridLayers[id] = L.geoJson(geom, tileOptions).addTo(this.map).on("click", this.tileClick).on("mouseover", this.highlight).on("mouseout", this.resetHighlight);
      }
    },
    /**
     * This functions calls the leaflet invalidateSize function
     */
    invalidateMapSize() {
      this.map.invalidateSize();
    },
    highlight(event) {
      let tileID = event.target.options.tileID;
      this.gridLayers[tileID].setStyle({ color: "#ff7800", weight: 2, dashArray: null, fillOpacity: 0.2 });
    },
    resetHighlight(event) {
      let tileID = event.target.options.tileID;
      if (this.selectedGridTile != tileID){
        this.gridLayers[tileID].setStyle({ color: "#ff7800", weight: 1, dashArray: '3, 3', fillOpacity: 0 });
      }
      else {
        this.gridLayers[this.selectedGridTile].setStyle({ color: "#ff7800", weight: 1, dashArray: null, fillOpacity: 0 });
      }
    },
    tileClick(event) {
      this.prepareStreamTile(event.target.options.tileID);
    },
    prepareStreamTile(tileID){
      // reset tileMetadata
      this.tileMetadata = {};
      this.tileMetadata.id = tileID;
      // reset style
      if (this.selectedGridTile) {
        this.gridLayers[this.selectedGridTile].setStyle({ color: "#ff7800", weight: 1, dashArray: null });
      }
      console.log('Tile ' + this.tileMetadata.id + ' was clicked...');    
      this.selectedGridTile = tileID;  
      this.loadTileBounds(this.tileMetadata.id);
      this.loadTileMetadata(this.tileMetadata.id);
      // if new tile is loaded, it should not be playing
      this.videoPlaying = false;
    },
    loadTileVideo(startFrame=1) {
      if (this.videoLayer != null) {
        this.map.removeLayer(this.videoLayer);
        this.videoLayer = null;
        this.stopVideoWatcher();
        this.stopVideoReadyChecker();
        eventBus.$emit("reset-play-button", null);
      }

      // let fileName = this.selectedGridTile + '_TCI_3857';
      // let fileExtension = !this.isIOs ? ".webm" : ".mp4";

      // if (this.streamResolution == '2k')
      //   fileName += '_2k_complete'.concat(fileExtension);
      // else if (this.streamResolution == '4k')
      //   fileName += '_4k_complete'.concat(fileExtension);
      // else if (this.streamResolution == '11k')
      //   fileName += '_full_resolution_complete'.concat(fileExtension);
      // else
      //   console.log('Error when determining stream URL');

      this.getUrl(this.selectedGridTile, this.streamResolution).then(url => {
        console.log('URL:', url);
        console.log('Loading stream ' + url);
        this.videoLayer = L.videoOverlay(url, this.tileMetadata.videoBB, { interactive: true, pane: 'videoPane' } );
        
        this.videoLayer.addTo(this.map);
        this.videoElement = this.videoLayer.getElement();
        this.videoElement.crossOrigin = "anonymous"; // solves CORS issue
        this.videoElement.playbackRate = this.playbackRate;
        this.videoElement.loop = false;
        this.videoElement.autoplay = false;
        if (!this.isIOs) {
          this.showMapSpinner();
          this.startVideoReadyChecker();
        }
        this.videoElement.onplay = this.videoOnPlay;
        if (startFrame && startFrame > 1) {
          this.jumpToTime(((startFrame-1) * 0.25) + 0.001);
        }
        this.currentFrame = Math.floor(this.videoElement.currentTime * this.framesPerSecond) + 1;
        //TODO: possibly improve this by combining it with the mapSpinner
        this.$store.commit("setVideoLoaded", true);
      });

      // let url = '';
      // if (process.env.VUE_APP_STAGE == 'production') {
      //   url = "https://csc-streamlined.geoville.com/data/" + fileName;
      //   console.log("URL:", fileName) 
      // } else if (process.env.VUE_APP_STAGE == 'development') {
      //   url = "https://dev.csc-streamlined.geoville.com/data/" + fileName;
      //   console.log("URL:", fileName)
        // axios_services.get(`/metadata/s2/tci/${this.selectedGridTile}`, {
        //   params: {
        //     fileName: fileName
        //   }
        // })
        // .then(response => {
        //   url = response.data.metadata.stream_2k;
        //   console.log("URL:", url);
        // })
        // .catch(error => {
        //   console.error("Error fetching URL:", error);
        // });
      //}
      // console.log('Loading stream ' + url);
      // this.videoLayer = L.videoOverlay(url, this.tileMetadata.videoBB, { interactive: true, pane: 'videoPane' } );
      
      // this.videoLayer.addTo(this.map);
      // this.videoElement = this.videoLayer.getElement();
      // this.videoElement.playbackRate = this.playbackRate;
      // this.videoElement.loop = false;
      // this.videoElement.autoplay = false;
      // if (!this.isIOs) {
      //   this.showMapSpinner();
      //   this.startVideoReadyChecker();
      // }
      // this.videoElement.onplay = this.videoOnPlay;
      // if (startFrame && startFrame > 1) {
      //   this.jumpToTime(((startFrame-1) * 0.25) + 0.001);
      // }
      // this.currentFrame = Math.floor(this.videoElement.currentTime * this.framesPerSecond) + 1;
      // //TODO: possibly improve this by combining it with the mapSpinner
      // this.$store.commit("setVideoLoaded", true);
    },
    jumpToTime(seconds) {
      this.videoElement.pause();
      this.videoElement.currentTime = seconds;
      this.currentFrame = Math.floor(this.videoElement.currentTime * this.framesPerSecond) +1;
      //console.log('current time: ' + this.videoElement.currentTime + ' and frame ' + this.currentFrame);
      if (this.videoPlaying){
        this.videoElement.play();
      }
      else {
        if (!this.isIOs) {
          this.startVideoReadyChecker();
        }
      }
    }, 
    jumpToFrame(frameNumber) {
      this.jumpToTime(((frameNumber - 1) * 0.25) + 0.001);
    },   
    pauseVideo(){
      //console.log('pausing video');
      this.videoElement.pause();
      this.videoPlaying = false;
      this.stopVideoWatcher();
      //console.log('current time: ' + this.videoElement.currentTime + ' and frame ' + this.currentFrame);
    },
    playVideo() {
      //console.log('playing video');
      this.videoElement.play();
      this.videoPlaying = true;
    },
    startVideoReadyChecker() {
      //console.log("trying to start videoReadyChecker. videoReadyChecker: " + this.videoReadyChecker);
      if (this.videoReadyChecker == null && !this.videoPlaying) {
        this.videoReadyChecker = setInterval(this.watchVideoReady, 100);
        //console.log("started videoReadyChecker: " + this.videoReadyChecker);
      }
    },
    stopVideoReadyChecker() {
      clearInterval(this.videoReadyChecker);
      this.videoReadyChecker = null;
    },
    stopVideoWatcher() {
      clearInterval(this.videoWatcher);
      this.videoWatcher = null;
    },
    showMapSpinner() {
      this.$store.commit("setViewerDisabled", true);
      if (!this.timeseriesExtractionMode) {
        this.map.spin(true, {lines: 13, length: 10});
        let spinner = document.getElementsByClassName("spinner")[0];
        const node = document.createElement("div");
        const textnode = document.createTextNode("Accessing " + this.tileMetadata.meta.jp2_size_gb + "GB of uncompressed S2 Data");
        node.classList.add('spinner-label');
        node.appendChild(textnode);
        spinner.appendChild(node);
      }
      this.mapSpinner = true;
    },
    removeMapSpinner() {
      this.$store.commit("setViewerDisabled", false);
      this.map.spin(false);
      this.mapSpinner = false;
    },
    extractTimeSeries(data) {
      this.timeseriesExtractionMode = true;
      this.timeseriesExtractionModalData = {
        imageType: data.imageType,
        targetCRS: data.targetCRS,
      };

      this.$store.commit("setToggleLoadingViewProgress", true);
      this.firstExtractionFrame = data.detectedStartDateFrame ;
      this.lastExtractionFrame = data.detectedEndDateFrame;
      let numberFrames = this.lastExtractionFrame - this.firstExtractionFrame;
      this.$store.commit("setExtractedFramesTotal", numberFrames+1);

      this.prepareStreamTile(data.tile.name);
    },
    extractCurrentFrameToGeotiff(modalData) {
      // Fit to tile
      let bbox = [
        [
          this.currentTileInfo.tileBoundsMercator4326.bbox[3],
          this.currentTileInfo.tileBoundsMercator4326.bbox[2]
        ],
        [
          this.currentTileInfo.tileBoundsMercator4326.bbox[1],
          this.currentTileInfo.tileBoundsMercator4326.bbox[0]
        ]
      ];
      this.map.fitBounds(bbox); 

      // Show waiting animation
      this.$store.commit("setToggleLoadingView", true);
      
      let imageData;
      let metadata;
  
      try {
        [ imageData, metadata ] = this.getImageDataFromVideoElement();
      } catch(err) {
        console.log(err);
        this.$store.commit("setToggleLoadingView", false);
        this.showNotification("danger", "Error!", "GeoTIFF could not be created", "b-toaster-top-right");
        return;
      } 

      let extractionData = {
        imageType: modalData.imageType,
        targetCRS: modalData.targetCRS,
        timestamp: modalData.timestamp,
        tileMetadata: this.tileMetadata,
        quality: this.streamResolution
      }
      
      // Apply geo-reference information
      this.$gdalServices.createSingleGeoTiff(imageData, metadata, this.tileMetadata, extractionData)
        .then(() => {
          console.log("georeferencing successful")
          this.$store.commit("setToggleLoadingView", false);
          this.showNotification("success", "Success!", "GeoTIFF successfully extracted", "b-toaster-top-right");
        })
        .catch((err) => {
          console.log("ERROR: ", err);
          this.$store.commit("setToggleLoadingView", false);
          this.showNotification("danger", "Error!", "GeoTIFF could not be extracted", "b-toaster-top-right");
        });
    },
    async extractCurrentFrameToGeotiffTimeseries(frameInfo, tileInfo) {
      
      let imageData;
      let metadata;
  
      try {
        [ imageData, metadata ] = this.getImageDataFromVideoElement();
      } catch(err) {
        console.log(err);
        this.$store.commit("setToggleLoadingView", false);
        this.showNotification("danger", "Error!", "GeoTIFF could not be created", "b-toaster-top-right");
        return;
      } 

      // console.log(frameInfo);
      // console.log(tileInfo);

      let dataForExtraction = {
        imageType: this.timeseriesExtractionModalData.imageType,
        targetCRS: this.timeseriesExtractionModalData.targetCRS,
        timestamp: frameInfo.date,
        tileMetadata: this.tileMetadata,
        tile: tileInfo.tileID,
        quality: this.streamResolution
      }
      
      // Apply geo-reference information
      await this.$gdalServices.createSingleGeoTiff(imageData, metadata, this.tileMetadata, dataForExtraction);
        // .then(() => {
        //   console.log("georeferencing successful")
        //   this.showNotification("success", "Success!", "GeoTIFF successfully extracted", "b-toaster-top-right");
        // })
        // .catch((err) => {
        //   console.log("ERROR: ", err);
        //   this.showNotification("danger", "Error!", "GeoTIFF could not be extracted", "b-toaster-top-right");
        // });
    },
    extractCurrentFrameToJPEG(data) {
      this.$store.commit("setToggleLoadingView", true);

      var canvas = document.getElementById('extraction-canvas');
      var ctx = null;

      canvas.width = this.videoElement.videoWidth;
      canvas.height = this.videoElement.videoHeight;

      if (canvas == null || canvas.width == 0 || canvas.height == 0) {
        this.$store.commit("setToggleLoadingView", false);
        this.showNotification("danger", "Error!", "GeoTIFF could not be created", "b-toaster-top-full");
        return;
      }

      ctx = canvas.getContext('2d', { alpha: false });
      ctx.drawImage(this.videoElement, 0, 0);
      let jpegFile = canvas.toDataURL("image/jpeg");
    
      let a = document.createElement("a"); 
      a.href = jpegFile;
      a.download = data.tileID + '_' + data.timestamp.split('T')[0];
      a.click(); 

      this.$store.commit("setToggleLoadingView", false);
      this.showNotification("success", "Success!", "Image successfully created", "b-toaster-top-full");
    },
    getImageDataFromVideoElement() {
      var canvas = document.getElementById('extraction-canvas');
      
      var imageData = null;
      var metadata = null;
      var ctx = null;

      canvas.width = this.videoElement.videoWidth;
      canvas.height = this.videoElement.videoHeight;

      if (canvas == null || canvas.width == 0 || canvas.height == 0) {
        throw new Error("canvas could not be loaded");
      }

      ctx = canvas.getContext('2d', { alpha: false });
      ctx.drawImage(this.videoElement, 0, 0);

      imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      metadata = {
        width: canvas.width,
        height: canvas.height,
      };

      return [imageData, metadata]
    },
    videoOnPlay() {
      //console.log('onplay current time: ' + this.videoElement.currentTime + ' and frame ' + this.currentFrame);
      if (this.videoWatcher == null && !this.isIOs) {
        this.videoWatcher = setInterval(this.watchVideoProgress, 100);
        //console.log("started watchVideoProgress: " + this.videoWatcher);
      }
    },
    watchVideoProgress() {
      this.currentFrame = Math.floor(this.videoElement.currentTime * this.framesPerSecond) + 1;

      //stream has passed end
      if (this.tileMetadata.images.length < this.currentFrame) {
        //console.log("passed the end of the stream");
        if (this.jumpToNextFrame) {
          this.jumpToNextFrame = false;
          this.videoPlaying = false;
          this.stopVideoWatcher();
        }
        this.jumpToTime(0);
      }
      //stream has reached end
      else if (this.tileMetadata.images.length == this.currentFrame) {
        //console.log("reached the end of the stream");
        this.pauseVideo();
        if (this.jumpToNextFrame) {
          this.jumpToNextFrame = false;
          this.videoPlaying = false;
          this.stopVideoWatcher();
        }
        eventBus.$emit("reset-play-button", null);
        if (this.mapSpinner){
          //console.log('removing spinner for watchVideoProgress');
          this.removeMapSpinner();
        }
      }
      //do it only if video is playing
      else if (this.videoElement.currentTime > 0 && !this.videoElement.paused && !this.videoElement.ended && this.videoElement.readyState > 2) {
        if (this.mapSpinner){
          //console.log('removing spinner for watchVideoProgress');
          this.removeMapSpinner();
        }
        if (this.jumpToNextFrame && this.currentFrame > this.lastFrame) {
            this.videoElement.playbackRate = this.playbackRate;
            this.pauseVideo();
            this.jumpToNextFrame = false;
            this.stopVideoWatcher();
            //console.log('next frame reached. current time: ' + this.videoElement.currentTime + ' and frame ' + this.currentFrame);
        }
      }
      else {
        //console.log('video is getting ready to play');
        if (!this.mapSpinner) {
          //console.log('adding spinner for watchVideoProgress');
          this.showMapSpinner();
        } 
      }
    },
    watchVideoReady() {
      // stop stopVideoReadyChecker and spinner once video is ready to play
      if (this.videoElement !=  null && this.videoElement.readyState > 2 && !this.videoElement.ended) {
        if (this.mapSpinner){
          //console.log('removing spinner for watchVideoReady');
          this.removeMapSpinner();
        }
        this.stopVideoReadyChecker();
        if (this.videoPlaying)
          this.playVideo();
      }
      else {
        //console.log('video is not loaded yet');
        if (!this.mapSpinner) {
          //console.log('adding spinner for watchVideoReady');
          this.showMapSpinner();
        }
      }
    },
    loadTileBounds(tileID) {
      this.tileBoundsLoading = true;
      axios_services
        .get(`/s2-tiles/bounds/${tileID}`)
        .then((response) => {
          let bbox = response.data.mercator_4326.bbox;
          this.tileMetadata.videoBB = [[bbox[3], bbox[2]],[bbox[1],bbox[0]]];
          this.tileMetadata.tileBoundsMercator3857 = response.data.mercator_3857,
          this.tileMetadata.tileBoundsMercator4326 = response.data.mercator_4326,
          this.tileMetadata.tileBoundsUtm = response.data.utm
          let payload = {
            tileID: tileID,
            tileBoundsMercator3857: this.tileMetadata.tileBoundsMercator3857,
            tileBoundsMercator4326: this.tileMetadata.tileBoundsMercator4326,
            tileBoundsUtm: this.tileMetadata.tileBoundsUtm,
          }
          this.$store.commit("setCurrentTileInfo", payload);
          this.tileBoundsLoading = false;
        })
        .catch((err) => {
          console.log("not able to load tilebounds for " + tileID + ": " + err);    
          this.tileBoundsLoading = false;
        });
    },
    loadTileMetadata(tileID) {
      this.tileMetadataLoading = true;
      axios_services
        .get(`/metadata/s2/tci/${tileID}`)
        .then((response) => {
          this.tileMetadata.images = response.data.images;
          this.tileMetadata.meta = response.data.metadata;
          this.$store.commit("setCurrentTileFrameCount", this.tileMetadata.images.length);
          this.tileMetadataLoading = false;

          // Show notification about data amount
          let dataAmount = this.tileMetadata.meta.jp2_size_gb;
          this.showNotification("info", "Accessing S2 data", `Accessing ${dataAmount} GB of uncompressed S2 data`, "b-toaster-top-center");
        })
        .catch((err) => {
          console.log("not able to load tile " + tileID + " metadata: " + err);    
          this.tileMetadataLoading = false;
        });
    },
    loadS2Tiles() {
      axios_services
        .get('/s2-tiles/overview')
        .then((response) => {
          let data = response.data.data;

          let tileData;
          if (process.env.VUE_APP_STAGE == 'production') {
            tileData = data.filter(d => d.status == true);
          } else if (process.env.VUE_APP_STAGE == 'development'){
            tileData = data;
          }
          
          this.addS2Tiles(tileData);
          this.$store.commit("setS2Tiles", response.data.data);
        })
        .catch((err) => {
          console.log("not able to load s2 tiles: " + err);    
        });
    },
    async getUrl(tileID, resolution) {
      try {
        let url = '';
        let fileName = this.selectedGridTile + '_TCI_3857';
        let fileExtension = !this.isIOs ? ".webm" : ".mp4";
        const response = await axios_services.get(`/metadata/s2/tci/${tileID}`);
        if (resolution == '2k') {
          url = response.data.metadata.stream_2k;
          if (!url.startsWith("https://s3")) {
            fileName += '_2k_complete'.concat(fileExtension);
            url = '';
          }
        }
        else if (resolution == '4k') {
          url = response.data.metadata.stream_4k;
          if (!url.startsWith("https://s3")) {
            fileName += '_4k_complete'.concat(fileExtension);
            url = '';
          }
        }
        else if (resolution == '11k') {
          url = response.data.metadata.stream_full;
          if (!url.startsWith("https://s3")) {
            fileName += '_full_resolution_complete'.concat(fileExtension);
            url = '';
          }
        }
        else
          console.log('Error when determining stream URL');
        if (process.env.VUE_APP_STAGE == 'production' && url == '') {
          url = "https://csc-streamlined.geoville.com/data/" + fileName;
        } else if (process.env.VUE_APP_STAGE == 'development' && url == '') {
          url = "https://dev.csc-streamlined.geoville.com/data/" + fileName;
        }
        return url;
      } catch (error) {
        console.error("Error fetching URL:", error);
      }
    },
    delay(ms) {
      return new Promise(res => setTimeout(res, ms));
    },
    showNotification(variant, title, content, toaster) {
      this.$bvToast.toast(content, {
        autoHideDelay: 4000,
        title: title,
        variant: variant,
        solid: true,
        toaster: toaster
      });
    },
  },
  computed: {
    playbackRate() {
      return this.$store.getters.getPlaybackRate;
    },
    metaDataLoaded() {
      return !(this.tileBoundsLoading || this.tileMetadataLoading);
    },
    streamResolution() {
      return this.$store.getters.getStreamingQuality;
    },
    isIOs() {
      return this.$store.getters.getIsIOs;
    },
    extractFrameReady() {
      return (!this.jumpToNextFrame && !this.mapSpinner)
    },
    currentFrameInfo() {
      return this.$store.getters.getCurrentFrameData;
    },
    currentTileInfo() {
      return this.$store.getters.getCurrentTileInfo;
    },
  },
  watch: {
    /**
     * This watch function listens to the toogle event of the parent component (sidebar). The events is  passed to the component
     * by the `invalidateSize` prop
     */
    invalidateSize() {
      this.invalidateMapSize();
    },
    playbackRate() {
      //update video playback rate
      if(this.videoElement != null) {
        this.videoElement.playbackRate = this.playbackRate;
      }
    },
    metaDataLoaded() {
      if (this.tileMetadata.videoBB != null && this.tileMetadata.images != null) {
        if (this.firstExtractionFrame != null) {
          this.loadTileVideo(this.firstExtractionFrame);
          let bbox = [
            [
              this.currentTileInfo.tileBoundsMercator4326.bbox[3],
              this.currentTileInfo.tileBoundsMercator4326.bbox[2]
            ],
            [
              this.currentTileInfo.tileBoundsMercator4326.bbox[1],
              this.currentTileInfo.tileBoundsMercator4326.bbox[0]
            ]
            ];
          this.map.fitBounds(bbox);
        }
        else {
          this.loadTileVideo();
        }
      }
    },
    currentFrame() {
      try {
        let frameIndex = this.currentFrame - 1;
        let payload = {
          frameNumber: this.currentFrame, 
          date: this.tileMetadata.images[frameIndex].sensing_time, 
          cloudCoverage: this.tileMetadata.images[frameIndex].cloudy_pixel_percentage, 
          dataCoverage: this.tileMetadata.images[frameIndex].nodata_pixel_percentage
        }
        this.$store.commit("setCurrentFrameData", payload);
      } catch(err) {
        console.log("Exception while updating currentFrame value: " + err);
      }
    },
    streamResolution() {
      if (this.tileMetadata.id) {
        //console.log('replacing video with different resolution');
        //use this.currentFrame as param of this.loadTileVideo if you want to continue at last frame
        this.loadTileVideo();
      }
      else {
        //console.log('no tile selected -> nothing to do');
      }
    },
    async extractFrameReady(readyValue) {
      /*
        This watcher recognizes a combined event - next frame reached and loading spinner false
      */
      if (this.timeseriesExtractionMode) {
        if (readyValue) {
          // Call the extraction plugin
          await this.delay(1000);
          await this.extractCurrentFrameToGeotiffTimeseries(
            this.currentFrameInfo,
            this.currentTileInfo
          ).then(() => {
            this.extractionCounter = this.extractionCounter + 1;
            this.$store.commit("setExtractedFrames", this.extractionCounter);
          }).catch((err) => {
            console.log("extraction for ", this.currentFrameInfo, " failed. Details: ", err);
            this.showNotification("danger", "Error!", "GeoTIFF could not be created", "b-toaster-top-right");
            this.extractionCounter = this.extractionCounter + 1;
            this.$store.commit("setExtractedFrames", this.extractionCounter);
          });

          this.jumpToNextFrame = true;
          this.lastFrame = this.currentFrame;
          this.videoElement.playbackRate = 1;
          this.videoElement.play();

          if (this.currentFrame >= this.lastExtractionFrame) {
            this.timeseriesExtractionMode = false;
            this.firstExtractionFrame = null;
            this.lastExtractionFrame = null;

            setTimeout(() => {
              this.$store.commit("setToggleLoadingViewProgress", false);
              this.$store.commit("setExtractedFrames", 0)
              this.extractionCounter = 0;
              this.timeseriesExtractionModalData = null;
            }, 600);
          }
        }
      }
    }
  },
};
</script>

<style scoped>
#map {
  width: auto;
  height: 100%;
}

#extraction-canvas {
  display: none;
}

</style>
